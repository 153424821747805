/**
 * Represents the UI that helps the user make fine-grained edits in the level editor.
 */
#editor-info {
	position: fixed;
	opacity: 0.9;
	transform: translate(0, -50%);
	margin-right: 10px;
	right: 0;
	top: 50%;
	width: 15em;
	justify-content: left;
	border-radius: 4px;
	background-color: #222426;
	box-shadow: 0 0 12px #222426;
	padding: 1em;
	color: white;
	font-weight: bold;
	transition: 0.4s transform, 0.4s opacity;
}

#editor-info.hidden {
	transform: translate(110%, -50%);
	opacity: 0;
}

#editor-info label {
	display: block;
}

/**
 * Places X and Y fields side-by-side.
 */
#editor-info .editor-x {
	width: 45%;
	float: left;
}

#editor-info .editor-y {
	width: 45%;
	float: right;
}

/**
 * Extends fields to fill the entire width of the editor UI.
 */
#editor-info .editor-field {
	width: -webkit-fill-available; /* Chrome, Opera, Safari */
	width: -moz-available; /* Firefox */
}

/**
 * The editor's header.
 */
#editor-info-header {
	margin: 0 0 1em 0;
}

/**
 * Represents the level difficulty selector.
 */
#difficulty-selector {
	display: flex;
	margin: 0.2em 0;
}

/**
 * Represents the shape selector when adding / editing a wall.
 */
#shape-selector {
	margin: 0.2em 0;
	width: 100%;
}

.shape-selector label {
	font-size: 1.3em;
}
