/**
 * Represents the options the user can choose from in the play menu.
 */
#play-options {
	align-items: center;
	justify-content: space-evenly;
	height: 80%;
	margin: auto;
}

/**
 * Represents one of the options the user can choose from in the play menu.
 */
.play-option {
	display: flex;
	align-items: center;
	gap: 2em;
	width: 810px;
	cursor: pointer;
	user-select: none;
	transition: 0.3s transform, 0.3s opacity, 0.3s background-color;
}

.play-option h1 {
	margin: 0;
	text-align: left;
}

.play-option p {
	margin: 0;
	font-size: 1.5em;
}

.play-option:hover {
	background-color: #4480ff;
}
