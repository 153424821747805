/**
 * Represents the buttons the user can click on, on the completion screen.
 */
#complete-options {
	display: flex
}

#complete-options * {
	margin: 0 auto;
}
