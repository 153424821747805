.levels-list-info table {
	width: 100%;
	border-collapse: collapse;
}

.levels-list-info table thead {
	position: sticky;
	top: 0;
}

.levels-list-info table th {
	background-color: #141414;
	font-size: 1.4em;
	padding: 0.75rem 0.5rem;
}

.levels-list-info table th:first-child {
	border-radius: 6px 0 0 6px;
}

.levels-list-info table th:last-child {
	border-radius: 0 6px 6px 0;
}

.levels-list-info table tr {
	font-size: 1.2em;
	transition: 0.3s color;
}

.levels-list-info table tbody tr:hover {
	color: #4480ff;
}

/**
 * Represents the text that replaces the leaderboard results when there is nothing to show.
 */
#leaderboards-blank {
	height: 100%;
	justify-content: center;
}
