/**
 * Represents the list of settings options.
 */
#settings-list {
	margin: auto 12em;
}

/**
 * Represents a setting option.
 */
.setting {
	position: relative;
}

/**
 * Represents a setting's title and description.
 */
.setting-block {
	display: inline-block;
	width: 50%;
}

.setting .switcher {
	position: absolute;
	right: 0;
	top: 50%;
	width: 40%;
}
