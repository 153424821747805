/**
 * Represents the list of rooms in the browser.
 */
#room-list {
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	height: 80%;
	margin: auto;
}

#room-list table {
	padding: 0 1em;
	border-spacing: 0 1em;
}

#room-list th {
	padding: 0 2.5em;
	font-size: 1.5em;
}

#room-list td {
	padding: 0.5em;
}

/**
 * Represents a room in the room browser the user can select.
 */
.room-list-entry {
	outline: solid white;
	font-size: 1.3em;
	user-select: none;
	cursor: pointer;
	transition: 0.3s background-color, 0.3s color, 0.3s outline-color;
}

.room-list-entry:hover:not(.selected) {
	color: #4480ff;
	outline-color: #4480ff;
}

/**
 * Represents the refresh button the room browser.
 */
#room-browser-refresh {
	flex-basis: 7em;
}
