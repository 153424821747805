/**
 * Represents the level selection screen.
 */
#level-select {
	align-items: center;
	z-index: 1;
}

/**
 * The title of the level selection screen in **solo mode**.
 */
#level-select > h1 {
	position: sticky;
	top: 0.2em;
	z-index: -1;
}

#level-select-init {
	position: sticky;
	top: 1em;
	text-align: center;
}

#level-select-init > p {
	margin: 0;
}

/**
 * A version of `.option` for the game mode icons in the level selection screen.
 */
#game-mode-select .game-mode-option {
	background-color: transparent;
	margin: 0 7px;
	cursor: pointer;
	transition: 0.3s color;
}

.game-mode-option:hover, .game-mode-option.selected {
	color: #4480ff;
}

#game-mode-select {
	display: flex;
	justify-content: center;
}

/**
 * The levels to choose from, structured as a grid.
 */
.levels-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 1em;
	z-index: -1;
}

/**
 * The levels to choose from, structured as a list.
 */
.levels-list {
	background-color: #ffffff26;
	min-width: min-content;
	padding: 0 1em;
	user-select: none;
}

#levels-list-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#levels-list-header > h2 {
	margin-bottom: 0;
	margin-top: 0.5em;
}

/**
 * Represents the list selection and the info display next to it.
 */
.levels-list-content {
	display: flex;
	gap: 2em;
	height: 80%;
	margin: auto 3em;
}

/**
 * Represents the modal next to the level selection list.
 */
.levels-list-info {
	background-color: #1f2122;
	width: 100%;
	text-align: center;
}

/**
 * Represents one of the levels in the level selection grid.
 */
.grid-level {
	display: flex;
	align-items: center;
	border-radius: 10px;
	column-gap: 1em;
	cursor: pointer;
	user-select: none;
	transition: 0.3s transform, 0.3s opacity, 0.3s background-color;
}

.grid-level:hover:not(.disabled) {
	background-color: #4480ff;
}

/**
 * Represents one of the levels in the level selection list.
 */
.list-level {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4em;
}

.level-select-level-info > h1, .level-select-level-info > h2, .level-select-level-info > h3 {
	margin: 0;
}

.medal-indicators {
	height: 60px;
	transform: translateZ(0);
}

.medal-goal-indicator {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	bottom: 0;
	top: 0;
	transform: translateX(-50%);
	padding-top: 60px;
	transition: 0.3s all;
}

.medal-goal-indicator > p {
	margin: 0;
}

.medal-goal-indicator-bar {
	background-color: #ececec;
	position: fixed;
	bottom: 0;
	top: 0;
	width: 4px;
	padding-top: 50px;
	transition: 0.3s all;
}
