html {
	scroll-behavior: smooth;
}

body, canvas {
	background-color: black;
	font-family: 'Poppins', sans-serif;
	margin: 0;
	outline: none;
	height: 100%;
	display: block;
	overflow: hidden;
}

h1 {
	text-align: center;
	margin: 0.2em;
	font-size: 3em;
}

button, input[type="submit"] {
	background-color: #424243;
	color: white;
	border-radius: 4px;
	border: none;
	margin: 0.2em 0;
	padding: 1px 0.3em;
	font-family: 'Poppins', sans-serif;
	font-size: 1.8em;
	font-weight: bold;
	cursor: pointer;
	user-select: none;
	transition: 0.3s background-color, 0.3s color, 0.3s opacity;
}

button:hover, input[type="submit"]:hover {
	background-color: #606060;
}

button:disabled, input[type="submit"]:disabled {
	background-color: #343537;
	color: #909192;
	cursor: not-allowed;
}

input[type="text"], input[type="password"], input[type="number"] {
	background-color: #070708;
	font-family: 'Poppins', sans-serif;
	color: #d9d9d9;
	border-radius: 4px;
	border: 1px solid #606060;
	padding: 4px;
	margin: 0.2em 0;
	font-size: 1.6em;
	line-height: 1.1em;
	transition: 0.3s border;
}

input[type="color"] {
	background-color: #070708;
	border-radius: 4px;
	border: 1px solid #606060;
	margin: 0.4em 0;
	width: -webkit-fill-available; /* Chrome, Opera, Safari */
	width: -moz-available; /* Firefox */
}

input[type="radio"] {
	appearance: none;
	margin: 0 0.8em 0 0;
	width: 2em;
	height: 2em;
	background-color: #070708;
	border: 1px solid #606060;
	border-radius: 50%;
	cursor: pointer;
	transition: 0.3s background-color, 0.3s border;
}

input[type="radio"]:hover {
	background-color: #606060;
}

input[type="radio"]:checked {
	background-color: #4480ff;
	border: 1px solid #4480ff;
	cursor: default;
}

figcaption {
	font-style: italic;
}

a {
	color: #4480ff;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}
