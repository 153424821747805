/**
 * Contains the room header, room info, and footer buttons.
 */
#room {
	height: 100%;
}

/**
 * Represents the room info section, including the players in the room, the current map, and the chatbox.
 */
#room-content {
	display: flex;
	height: 80%;
	margin-block: auto;
	margin-inline: 3em;
}

#room-content h2 {
	margin: 0;
}

/**
 * Represents the player / spectator list and current map section.
 */
#room-content-info {
	flex: 2;
}

/**
 * Represents the player / spectator list.
 */
#room-content-users {
	display: flex;
}

#room-content-users > div {
	width: 100%;
	height: min-content;
}

/**
 * Represents the current map display.
 */
#room-content-map {
	display: flex;
	align-items: center;
}

/**
 * Represents the current map information.
 */
#room-content-map-info {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

#room-content-map-info > * {
	padding: 0 1.5em;
}
