/**
 * Represents the "...thanks to these people" text.
 */
.about-line {
	margin: 1em;
}

/**
 * Represents the credits section on the about page.
 */
#about-credits {
	display: flex;
}

#about-credits > div {
	flex: 1;
}

/**
 * Represents one of the users in the credits section.
 */
.about-user-container h3, .about-user-container p {
	margin: 0;
}

/**
 * Represents the avatars on the about page.
 */
.about-image {
	background-color: #000000b5;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	margin-inline: 1em;
	transition: 0.3s transform;
}

/**
 * Represents the table of testers on the about page.
 */
#about-testers {
	font-size: 1.2em;
}
