/**
 * Represents the main menu with all the options the user can choose from.
 */
#menu {
	background-color: #000000b5;
	position: fixed;
	left: 0;
	width: 460px;
	height: 100%;
	box-sizing: border-box;
	color: white;
	padding: 0 1em;
	user-select: none;
	transition: 0.3s left;
}

#menu-options .option {
	border: solid;
	box-shadow: -2px 2px 8px 0 white;
}

#menu-options .option:hover {
	box-shadow: -2px 2px 8px 0 #4480ff;
}

#menu.hidden {
	left: -460px;
	opacity: 1;
	pointer-events: all;
}

/**
 * Represents the toggle button the user can click to open the menu.
 */
#menu-toggle {
	background-color: #000000b5;
	position: fixed;
	left: 460px;
	top: 50%;
	transform: translate(0%, -50%);
	width: 60px;
	height: 120px;
	border-radius: 0 60px 60px 0;
	cursor: pointer;
	transition: 0.3s left;
}

#menu-toggle.hidden {
	left: 0;
	opacity: 1;
	pointer-events: all;
}

#menu-toggle-icon {
	position: relative;
	left: -25%;
	width: 150%;
	height: 100%;
	transition: 0.3s all;
}

#menu-toggle-icon.flip {
	transform: scale(-1, 1) translate(15px, 0);
}

#menu-toggle-icon:hover {
	filter: drop-shadow(0 0 4px white);
}
