/**
 * Highlights input fields and text red if they have the `.error` class.
 */
input.error {
	border: 2px solid #ff0000;
}

p.error {
	color: #ff0000;
}

.italic {
	font-style: italic;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.height-100 {
	height: 100%;
}

.button-row {
	display: flex;
	position: sticky;
	bottom: 0;
	justify-content: center;
	gap: 3em;
	margin: 1em;
}

/**
 * Represents moderate amounts of text content in a <div/>.
 */
.content {
	text-align: justify;
	padding: 1em;
	font-size: 1.1em;
}

/**
 * Represents the game logo.
 */
.logo {
	display: block;
	width: 380px;
	padding: 0.5em 0;
	margin: auto;
}

/**
 * Represents a small pop-up window in the middle of the screen. A `.dialog` element can have the `.hidden` class to animate it out.
 */
.dialog {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 1em;
	border-radius: 4px;
	background-color: #222426;
	box-shadow: 0 0 12px #222426;
	color: white;
	text-align: center;
	border: 1px solid #606060;
	justify-content: center;
	transition: .4s opacity, .4s transform;
}

.dialog.hidden {
	transform: translate(-50%, -50%) scale(0.9);
}

/**
 * Represents a modal-like pop-up window.
 */
.modal {
	background-color: #141414;
	border-radius: 6px;
	padding: 1em;
	margin: 1em;
	opacity: 80%;
	box-shadow: 4px 4px 4px #000000fa;
	transition: 0.3s opacity, 0.3s transform;
}

.modal:hover {
	opacity: 100%;
	transform: scale(1.05);
}

.modal.disabled {
	cursor: not-allowed;
	opacity: 20%;
	transform: scale(1);
}

/**
 * Represents a permanently-hovered-over modal.
 */
.perm-modal {
	padding: 1em;
	background-color: #141414;
	border-radius: 6px;
	box-shadow: 4px 4px 4px #000000fa;
}

/**
 * Represents a window that covers the entire screen. A `.window` element can have the `.hidden` class to animate it out.
 */
.window {
	position: fixed;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-color: #000000cc;
	color: white;
	transition: 0.3s opacity;
}

/**
 * Represents the "mobile unsupported" window.
 */
#mobile-unsupported {
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

/*
 * Represents a window / dialog that can be scrolled through.
 */
.scrollable {
	overflow-y: scroll;
}

.dialog.scrollable {
	max-height: 70%;
}

/**
 * Hides the scrollbar for Chrome, Safari and Opera, while preserving functionality.
 */
.scrollable::-webkit-scrollbar {
	display: none;
}

/**
 * Hide scrollbar for IE, Edge and Firefox, while preserving functionality.
 */
.scrollable {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

/**
 * Represents an option the user can choose from a list of options. This is *not* a dropdown list (see the main menu sidebar).
 */
.option {
	background-color: #101010c2;
	padding: 0.5em;
	margin: 1em 0;
	border-radius: 6px;
	user-select: none;
	cursor: pointer;
	transition: 0.3s all;
}

.option svg {
	margin: 0.1em 0.8em;
	float: left;
}

.option * {
	margin: 0;
}

.option h2 {
	font-size: 2em;
}

.option p {
	font-size: 1.5em;
}

.option:hover:not(.selected) {
	color: #4480ff;
}

.option.disabled {
	color: white;
	opacity: 0.3;
	cursor: not-allowed;
}

/**
 * Removes bullet points from unordered lists.
 */
ul.no-bullets {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

/**
 * Represents a switcher used to switch between multiple states.
 */
.switcher {
	display: flex;
	background-color: #212223;
	border-radius: 6px;
}

.switcher input {
	display: none;
}

.switcher label {
	background-color: #474748;
	border-radius: 6px;
	position: relative;
	flex-grow: 1;
	padding: 0.1em 0.4em;
	margin: 0.2em;
	font-size: 1.5em;
	text-align: center;
	cursor: pointer;
	transition: 0.3s background-color;
}

.switcher label:hover {
	background-color: #606060;
}

.switcher input:checked + label {
	background-color: #4480ff;
	cursor: default;
}

/**
 * Represents any element that is currently selected.
 */
.selected {
	background-color: #4480ff;
	outline-color: #4480ff;
}

/**
 * Hides an element from the screen, making it uninteractable.
 */
.hidden {
	opacity: 0;
	pointer-events: none;
}

.progress-bar {
	background-color: #141414;
	border-radius: 8px;
	height: 20px;
	overflow: hidden;
	transform: translateZ(0);
}

.progress-bar-inner {
	background-color: #ececec;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	transition: 0.3s all;
}
