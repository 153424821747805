/**
 * Represents the chat window in a room.
 */
#chat {
	position: relative;
	width: 40%;
}

/**
 * Represents the chat input box.
 */
#chat-input {
	flex: 0.05;
	position: relative;
	width: -webkit-fill-available; /* Chrome, Opera, Safari */
	width: -moz-available; /* Firefox */
	margin: 0;
}

/**
 * Represents the messages in the chat box.
 */
#chat-messages {
	flex: 1;
	width: -webkit-fill-available; /* Chrome, Opera, Safari */
	width: -moz-available; /* Firefox */
	padding: 0.4em;
}

/**
 * Represents the button the user can click on to scroll to the bottom of the chat.
 */
#chat-scroll-to-bottom {
	background-color: #000000b5;
	opacity: 1;
	position: absolute;
	right: 50%;
	bottom: 75px;
	transform: translate(50%, 0);
	width: 38px;
	height: 38px;
	border-radius: 38px;
	cursor: pointer;
	transition: 0.3s bottom, 0.3s transform;
}

#chat-scroll-to-bottom:hover {
	transform: translate(50%, 0) scale(1.1);
}

#chat-scroll-to-bottom.hidden {
	bottom: 15px;
	transform: translate(50%, 0) scale(1);
}

#chat-scroll-to-bottom-icon {
	position: relative;
	left: 10%;
	top: 12.5%;
	width: 80%;
	height: 80%;
	transform: rotate(90deg);
	transition: 0.3s transform;
}

#chat-scroll-to-bottom-icon.flip {
	transform: rotate(-90deg);
}

/**
 * Represents a chat message.
 */
.chat-message {
	margin: 0.4em;
	font-size: large;
}

.chat-message p {
	margin: 0;
	word-break: break-all;
	white-space: pre-wrap;
}

.chat-message span {
	font-weight: bold;
}
